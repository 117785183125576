import React, { Fragment } from "react";
import { Link } from "react-router-dom";
// import { UncontrolledCollapse } from "reactstrap";
const SideBar = ({ sidebarIsOpen, handleSidebar }) => {
  return (
    <Fragment>
      <div className="sidebar-menu-wrapper">
        <div
          id="sidebar-menu"
          className={`sidebar-menu ${sidebarIsOpen ? "active" : null}`}
        >
          <div className="dismiss text-right mb-60 clearfix">
            <span className="close-btn" onClick={handleSidebar}>
              <i className="flaticon-cancel-music"></i>
            </span>
          </div>

          <div className="btns-group ul-li mb-60 clearfix">
            <h2 className="sidebar-title mb-30">Cadastro e Download</h2>
            <p className="mb-45">
              Se é personal e ainda não fez o cadasto, clique aqui:
            </p>
            <a
              href="https://www.coach.trainme.com.br/signup"
              target={"_blank"}
              rel="noopener noreferrer"
              className="custom-btn"
            >
              Crie sua conta grátis
            </a>
            <p></p>
            <br></br>
            <p className="mb-45">
              Se é aluno e quer baixar o app, acesse os links:
            </p>
            <ul className="clearfix">
              <li>
                <Link to="https://play.google.com/store/apps/details?id=br.com.trainme.app" className="store-btn bg-default-blue" target="_blank">
                  <span className="icon">
                    <i className="flaticon-google-play"></i>
                  </span>
                  <strong className="title-text">
                    <small>available on</small>
                    google play
                  </strong>
                </Link>
              </li>
              <li>
                <Link to="https://apps.apple.com/us/app/train-me/id6482847649" className="store-btn bg-default-black">
                  <span className="icon">
                    <i className="flaticon-apple"></i>
                  </span>
                  <strong className="title-text">
                    <small>disponível na</small>
                    apple store
                  </strong>
                </Link>
              </li>
            </ul>
          </div>

        </div>
        <div
          onClick={handleSidebar}
          className={`overlay ${sidebarIsOpen ? "active" : null}`}
        ></div>
      </div>
    </Fragment>
  );
};

export default SideBar;
