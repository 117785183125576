import React, { useState } from "react";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";

import classnames from "classnames";
import TitleSection from "../Titlesection/TitleSection";
import titleTwo from "../../data/title/title-two.json";

const [{ title, Paragraph }] = titleTwo;

const FeaturesNav = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <section
        id="extra-features-section"
        className="extra-features-section sec-ptb-160 bg-light-gray clearfix"
      >
        <Container>
          <Row className="justify-content-center">
            <Col sm="12" lg="6" md="8">
              {
                <TitleSection spacer="mb-100" textAlign="text-center">
                  <h2 className="title-text mb-30">{title}</h2>
                  <p className="paragraph-text mb-0">{Paragraph}</p>
                </TitleSection>
              }
            </Col>
          </Row>

          <div className="features-nav ul-li-center mb-100">
            {/* class="nav" */}
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  <span className="item-icon">
                    <img
                      src="assets/images/trainme/app.svg"
                      alt="App TrainMe"
                    />
                  </span>
                  Use o App
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  <span className="item-icon">
                    <img
                      src="assets/images/trainme/registre.svg"
                      alt="Registre"
                    />
                  </span>
                  Registre seu Dia a dia
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  <span className="item-icon">
                    <img
                      src="assets/images/trainme/evolucao.svg"
                      alt="Evolução"
                    />
                  </span>
                  Acompanhe a evolução
                </NavLink>
              </NavItem>
            </Nav>
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row className="justify-content-lg-start justify-content-md-center">
                <Col sm="12" md="8" lg="6">
                  <div className="feature-content">
                    <h3 className="item-title mb-30">
                      Fácil para seu aluno,
                      <span className="break-point">
                        melhor para você
                      </span>
                    </h3>
                    <p className="mb-30">
                      Treino, dieta e Acompanhamento de maneira simples. Seu aluno executa o protocolo
                      assistindo vídeos, anotando cargas (nada de caderninho), e ainda adiciona sua própria evolução.
                    </p>
                    <div className="instructions-list ul-li-block">
                      <ul className="clearfix">
                        <li>
                          <i className="flaticon-checked"></i> Acesso ao treino programado
                        </li>
                        <li>
                          <i className="flaticon-checked"></i> Vídeos com execução de exercícios
                        </li>
                        <li>
                          <i className="flaticon-checked"></i> Chat com o consultor
                        </li>
                        <li>
                          <i className="flaticon-checked"></i> Input de evolução
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" lg="6" md="8">
                  <div className="feature-image text-center">
                    <img
                      src="assets/images/features/img-1.png"
                      alt="image_not_found"
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row className="justify-content-lg-start justify-content-md-center">
                <Col sm="12" md="8" lg="6">
                  <div className="feature-content">
                    <h3 className="item-title mb-30">
                      Começo, meio e fim,
                      <span className="break-point">
                        em todo ciclo
                      </span>
                    </h3>
                    <p className="mb-30">
                      Dia a dia, semana a semana, os treinos na palma da mão do seu cliente,
                      e você acompanhando gerenciando tudo o que quiser.
                    </p>
                    <div className="instructions-list ul-li-block">
                      <ul className="clearfix">
                        <li>
                          <i className="flaticon-checked"></i> Biblioteca de treino
                        </li>
                        <li>
                          <i className="flaticon-checked"></i> Configuração personalizada
                        </li>
                        <li>
                          <i className="flaticon-checked"></i> Cadasto de alunos facilitado
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" lg="6" md="8">
                  <div className="feature-image text-center">
                    <img
                      src="assets/images/features/img-2.png"
                      alt="image_not_found"
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row className="justify-content-lg-start justify-content-md-center">
                <Col sm="12" md="8" lg="6">
                  <div className="feature-content">
                    <h3 className="item-title mb-30">
                      Resultados diáros,
                      <span className="break-point">
                        para você e seu cliente
                      </span>
                    </h3>
                    <p className="mb-30">
                      Acompanhe seu aluno de perto, crie treinos, dietas e acompanhe a evolução dele por fotos.
                    </p>
                    <div className="instructions-list ul-li-block">
                      <ul className="clearfix">
                        <li>
                          <i className="flaticon-checked"></i> Questionários de Anaminese, ParQ e personalizados
                        </li>
                        <li>
                          <i className="flaticon-checked"></i> Fotos dos alunos
                        </li>
                        <li>
                          <i className="flaticon-checked"></i> Evolução de carga
                        </li>
                        <li>
                          <i className="flaticon-checked"></i> Histórico de protocolos
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>

                <Col sm="12" lg="6" md="8">
                  <div className="feature-image text-center">
                    <img
                      src="assets/images/features/img-3.png"
                      alt="image_not_found"
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Container>
      </section>
    </div>
  );
};

export default FeaturesNav;
