import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
//import { VideoModal } from "../Modal";

const featureTwo = () => {
  return (
    <Fragment>
      <section
        id="features-section"
        className="features-section sec-ptb-160 clearfix"
      >
        <Container>
          <div className="feature-item">
            <Row className="justify-content-lg-between justify-content-md-center">
              <Col lg="5" md="8" xs="12">
                <div className="feature-image-2 text-center">
                  <span className="item-image">
                    <img
                      src="assets/images/mockup/phone_4.png"
                      alt="image_not_found"
                    />
                  </span>
                  {/* <VideoModal /> */}
                </div>
              </Col>

              <Col lg="6" md="8" xs="12">
                <div className="feature-content p-0">
                  <h2 className="feature-item-title">
                    Mais que um aplicativo,{" "}
                    <span>um companheiro</span>
                  </h2>
                  <p className="mb-0">
                    As possibilidades são infinitas, e aqui já começamos lá do alto.
                    Seu aluno compartilha a evolução, acompanha treino, recebe sua dieta, e ainda fala com você pelo chat.
                  </p>

                  <div className="service-list ul-li clearfix">
                    <ul className="clearfix">
                      <li>
                        <div
                          className="item-icon"
                          style={{
                            backgroundImage: `url('assets/images/icons/bg-6.png')`,
                          }}
                        >
                          <i className="uil uil-lightbulb-alt"></i>
                        </div>
                        <div className="item-content">
                          <h3 className="item-title mb-15">
                            Acompanhamento de treinos
                          </h3>
                          <p className="mb-0">
                            Dia após dia seu aluno acompanha os exercícios e que você separou para ele.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div
                          className="item-icon"
                          style={{
                            backgroundImage: `url('assets/images/icons/bg-7.png')`,
                          }}
                        >
                          <i className="uil uil-atm-card"></i>
                        </div>
                        <div className="item-content">
                          <h3 className="item-title mb-15">Acompanhamento de Dietas</h3>
                          <p className="mb-0"> Nem só de frango e batata doce vive o fitness, e pra todas as combinações, seu aluno pode acompanhar por aqui.</p>
                        </div>
                      </li>
                      <li>
                        <div
                          className="item-icon"
                          style={{
                            backgroundImage: `url('assets/images/icons/bg-8.png')`,
                          }}
                        >
                          <i className="uil uil-shield-exclamation"></i>
                        </div>
                        <div className="item-content">
                          <h3 className="item-title mb-15">Evolução</h3>
                          <p className="mb-0">
                            Aqui seu aluno envia as fotos e você acompanha ele de perto com um sistema completo.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </Fragment>
  );
};

export default featureTwo;
